import React from "react"

export default ({ data }) => (
  <img
    src={"/arrow-down.svg"}
    className="h-16 w-16"
    alt=""
    onClick={() => {
      window.scrollTo({ top: 700, behavior: "smooth" })
    }}
  ></img>
)
