import "../css/global.css"
import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import ScrollTo from "../components/scrollTo"
import { Helmet } from 'react-helmet'

const green = "text-teal-500"

const CaseStudy = () => {
  let data = [
    {
      title: "Regents",
      desc: { text: "Web & Mobile" },
      url: "https://www.regentsschoolbali.com",
    },
    { title: "Balita", desc: { text: "Web" }, url: "https://balita.co" },
  ]
  let items = []
  data.map((item, i) =>
    items.push(
      <div
        onClick={() => window.open(item.url, "_blank")}
        className="w-full md:w-1/2 bg-gray-200 p-4 mt-3 md:mx-1 md:mt-10 up-on-hover cursor-pointer"
        key={i}
      >
        <div className="text-xs text-gray-500">CASE STUDY</div>
        <div className="mt-10">
          <div className="font-bold text-lg">{item.title}</div>
          <div className={`text-lg ${green}`}>{item.desc.text}</div>
        </div>
        <div className="mt-20 flex justify-between">
          <FontAwesomeIcon
            icon={faArrowRight}
            className="hover:text-teal-500 transition-colors transition-100 transition-linear"
          />
          <div className="text-xs text-gray-600 hover:font-bold">View Work</div>
        </div>
      </div>
    )
  )
  return (
    <div className="md:flex justify-center px-3 md:px-10 mb-24">{items}</div>
  )
}

export const query = graphql`
  query {
    workplace: file(relativePath: { eq: "digital-workplace.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({ data }) => (
  <div>
    <Helmet>
      <title>Mobile App Solution Company based in Bali, Indonesia | Digitify</title>
      <meta name="description" content="Digitify is a digital agency company based in Bali, Indonesia. We specialize in iOS and Android application strategy, design, and development." />
      <meta name="keywords" content="android developer, ios developer, mobile app, mobile app development, build app, app development, create mobile app, app creator, developer apps, mobile app design, digital agency bali, digital agency, digital agency indonesia" />
      <script type="text/javascript" async="" src="https://www.google-analytics.com/analytics.js"></script>
      <script async="" src="https://www.googletagmanager.com/gtag/js?id=UA-117575078-1"></script>
      <script>{`window.dataLayer=window.dataLayer || []; function gtag(){dataLayer.push(arguments)}gtag('js', new Date()); gtag('config', 'UA-117575078-1');`}</script>
    </Helmet>
    <Header page="home" />
    <div className="flex items-center justify-center flex-col">
      <div className="text-xs text-gray-400 mt-12">
        SUPERIOR DIGITAL AGENCY BASED IN BALI
      </div>
      <div className="text-3xl md:text-5xl mt-3 flex items-center justify-center flex-col mb-8">
        <div>
          Digitize your <span className={green}>business</span>
        </div>
        <div>with us!</div>
      </div>
      <ScrollTo />
    </div>
    <div className="flex justify-center relative">
      <Img
        fluid={data.workplace.childImageSharp.fluid}
        style={{ top: "100px", width: "100vw" }}
        alt="digital agency workplace"
      />
    </div>
    <div className="bg-black mt-4">
      <div className="md:flex justify-center px-10 py-40">
        <div className="py-5 px-3 w-full md:w-1/2">
          <h1 className="text-white">How can we help you?</h1>
          <div className="text-gray-400 mt-3 text-justify">
            A fundamental part of our job is to take care of our clients. We
            will ensure that our work together will leave an indelible impact.
            We are committed to you as a
            <span className={green}> top-notch Digital Agency </span>
            to bring your creation to life in the way you have imagined.
          </div>
        </div>
        <div className="py-5 px-3 md:w-1/4 text-right">
          <div className={`${green} text-xs`}>SERVICES</div>
          <div className="text-white mt-2">iOS</div>
          <div className="text-white mt-2">Android</div>
          <div className="text-white mt-2">Web</div>
          <div className="text-white mt-2">Games</div>
        </div>
      </div>
    </div>
    <div className="mt-20">
      <div className="md:flex justify-center px-10">
        <div className="font-bold text-2xl">Recent work</div>
      </div>
      <CaseStudy></CaseStudy>
    </div>
    <Footer />
  </div>
)
